/* Action types */
import { ModalPage } from "app/Dialog/dialog.types"
import { ActionMap, createActions } from "../../lib/reducer.types"
import { MenuTypeEnum, OffCanvasTypeEnum } from "./app.types"

export enum GlobalAppTypes {
  ToggleMenu = "Global/ToggleMenu",
  ToggleOffCanvas = "Global/ToggleOffCanvas",
  ToggleSidebar = "Global/ToggleSidebar",
  ToggleSettings = "Global/ToggleSettings",
  ToggleNav = "Global/ToggleNav",
  SetCurrentModal = "Global/Modal",
  Reset = "Global/Reset",
}

type Actions = {
  [GlobalAppTypes.ToggleMenu]: { key: MenuTypeEnum }
  [GlobalAppTypes.ToggleOffCanvas]: { key: OffCanvasTypeEnum | null }
  [GlobalAppTypes.ToggleSidebar]: { toggled?: boolean }
  [GlobalAppTypes.ToggleSettings]: { toggled?: boolean }
  [GlobalAppTypes.ToggleNav]: { toggled?: boolean }
  [GlobalAppTypes.SetCurrentModal]: { modal?: ModalPage }
  [GlobalAppTypes.Reset]: null
}

export type GlobalAppActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const GlobalAppActions = createActions<Actions>()
